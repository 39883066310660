html {
  background-color: #e6ded2;
}

body {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: inherit;
  font-size: inherit;
}

button:hover {
  cursor: pointer;
  color: white;
}

a {
  font-size: 1.3em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  text-decoration: none;
}

h1 {
  font-size: 3.5em;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
}

h2 {
  font-size: 1.5em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h3 {
  font-size: 1.4em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.topper a {
  font-size: 1.1em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-align: right;
}

h4 {
  font-size: 1.5em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.skilllevel {
  font-size: 1.2em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p {
  font-size: 1.1em;
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.hero {
  background-image: url(./hero.png);
  background-position: center;
  background-size: cover;
  background-repeat: none;
  padding-bottom: 5vh;
  height: 65vh;
}

.heroheader {
  height: 55vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.topper {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid black;
  width: 98%;
  padding: 1% 1% 1% 1%;
}

.bottomer {
  white-space: nowrap;
  width: 103%;
  margin-left: -3%;
  overflow: hidden;
  background-color: black;
  text-align: center;
  text-overflow: clip;
}

/*needs responsive fix*/

.aboutme {
  display: flex;
  justify-content: center;
  width: 69%;
  margin: 0 auto;
  max-width: 700px;
  padding: 20vh 0vw 20vh 0vw;
}

.subheading {
  width: 30%;
  text-align: center;
}

.subpara {
  width: 70%;
}

.projectcontainer {
  width: 75%;
  margin: 0 auto;
  padding: 5vh 5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.projects {
  width: 40%;
  margin: 2%;
  padding: 2%;
  border: 2px solid black;
  font-size: 1.4em;
  text-align: center;
}

img {
  width: 98%;
  height: 200px;
  border: 2px solid black;
  object-fit: cover;
  object-position: top;
}

.lightbeige {
  background-color: #f1f0ea;
  border-top: 2px solid black;
  width: 100%;
}

.skillcontainer {
  width: 70%;
  max-width: 700px;
  margin: 0 auto;
}

.skillheader {
  font-size: 1em;
  display: flex;
  justify-content: space-between;
}

.temp {
  margin: 5px 0px 25px 0px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid black;
  background-color: #deeed0;
  background-image: linear-gradient(to right, #deeed0, #e3c0db);
}

.download {
  border: 2px solid white;
  padding: 8px;
  color: white;
  font-size: 1.3em;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 1.8vh;
}

.download a {
  color: white !important;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
}

.row {
  width: 100%;
}

footer {
  background-color: #333230;
  padding: 3vh 2vw;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.black {
  border-bottom: 2px solid #333230;
}

form {
  margin: 0 auto;
  width: 70%;
  max-width: 700px;
  padding: 22vh 5vw;
}

input {
  outline: none;
  padding: 2%;
  width: 96%;
  background-color: white;
  border: none;
  font-size: 1.2em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  margin: 10px 0px;
}

textarea {
  resize: none;
  outline: none;
  padding: 2%;
  height: 20vh;
  margin: 10px 0px;
  width: 96%;
  background-color: white;
  border: none;
  font-size: 1.2em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
}

.formsubmit {
  margin-top: 2vh;
  background-color: #333230;
  color: white;
  font-size: 1.7em;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  padding: 5px 45px;
}

.formsubmit:hover {
  background-color: #e6ded2;
}

.submitmessage {
  height: 50vw;
  display: flex;
  align-items: center;
  padding: 10%;
  font-family: "Roboto Condensed", sans-serif;
  background-color: white;

  width: 80%;
  color: #333230 !important;
  text-align: center;
}

.submitmessagecontainer {
  width: 100%;
}

/*responsive*/

@media screen and (max-width: 650px) {
  h1 {
    font-size: 2.9em;
  }

  h2 {
    font-size: 1.7em;
  }

  h3 {
    font-size: 0.7em;
  }

  h4 {
    font-size: 1.5em;
  }

  .hero {
    background-position: 25% 75%;
  }

  .temp {
    height: 13px;
  }

  .aboutme {
    display: block;
    width: 70%;
    padding: 20vh 20vw 20vh 10vw;
  }

  .subheading {
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
  }

  .subpara {
    width: 100%;
  }

  .projectcontainer {
    justify-content: center;
  }

  .projects {
    width: 100%;
  }

  .skilllevel {
    font-size: 0.9em;
  }
}

@media screen and (min-width: 1100px) {
  h1 {
    font-size: 5em;
  }

  .submitmessage {
    height: 30vw;
  }

  .hero {
    height: 76vh;
  }

  .heroheader {
    height: 76vh;
  }
}

@media screen and (min-width: 1400px) {
  .hero {
    height: 86vh;
  }
}
